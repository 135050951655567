
.spinner-global {
    position: fixed;
    width: 100%;
    height: 100%;
}

.spinner,
.spinner:after {
    border-radius: 50%;
}
.spinner {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.sk-circle {
    align-self: center;
}

.sk-circle .sk-child {
    position: absolute;
}

.sk-white .sk-child:before {
    background-color: white;
}

.spinner-white {
    border-top: 1.1em solid #ffffff33;
    border-right: 1.1em solid #ffffff33;
    border-bottom: 1.1em solid #ffffff33;
    border-left: 1.1em solid #ffffff;
}

.spinner-primary {
    border-top: 1.1em solid #E0007A33;
    border-right: 1.1em solid #E0007A33;
    border-bottom: 1.1em solid #E0007A33;
    border-left: 1.1em solid #E0007A;
}

.spinner-secondary {
    border-top: 1.1em solid #f4f5f733;
    border-right: 1.1em solid #f4f5f733;
    border-bottom: 1.1em solid #f4f5f733;
    border-left: 1.1em solid #f4f5f7;
    background-color: #f4f5f7;
}
.spinner-default {
    border-top: 1.1em solid #172b4d33;
    border-right: 1.1em solid #172b4d33;
    border-bottom: 1.1em solid #172b4d33;
    border-left: 1.1em solid #172b4d;
    background-color: #f4f5f7;
}

.spinner-position-absolute {
    position: absolute;
}