html,
body {
    height: 100%;
    margin: 0;
    min-width: 990px;
    background-color: white;
}

#croo {
    height: 100%;
    overflow: hidden;
}

.collapse-icon {
    width: 24px;
}

.page-body {
    display: flex;
    flex-flow: row;
    height: 100%;
    background-color: white;
}

.sidebar {
    user-select: none;
    display: flex;
    flex-flow: column;
}

.sidebar .sidebar-top {
    height: 77px;
}

.sidebar.main-sidebar {
    width: 56px;
    flex-shrink: 0;
}

.sidebar.admin-sidebar {
    width: 264px;
    flex-shrink: 0;
    background-color: #f4f5f7;
    padding-left: 32px;
    padding-right: 32px;
}

.sidebar .section .title {
}

.sidebar .section {
    list-style: none;
}

.main-content {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    min-height: 100vh;
}

.location-header {
    min-height: 36px;
}

.main-content .navbar-top {
    top: 0;
    height: 64px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem 0 1rem;
    flex: 0 0 auto;
}

.Section__container___3YYTG {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    background: -webkit-linear-gradient(170deg, #e0007a 49%, #cc0066 50%);
    background: -o-linear-gradient(170deg, #e0007a 49%, #cc0066 50%);
    background: -moz-linear-gradient(170deg, #e0007a 49%, #cc0066 50%);
    background: linear-gradient(170deg, #e0007a 49%, #cc0066 50%);
}

.Section__container___1Gue_ {
    flex: auto;
    height: 100%;
    display: flex;
    background-color: #cc0066;
}

.no-select {
    user-select: none;
}

.ta-end {
    text-align: end;
}

.ta-center {
    text-align: center;
}

.text-sm-sm {
    font-size: 0.7rem !important;
}

.custom-control-label.text-sm-sm::after {
    top: 0;
}

.custom-control-label.text-sm-sm::before {
    top: 0;
}

.footer-link {
    color: #8898aa !important;
    margin-left: 2rem;
}

.form-sm .form-group {
    margin-bottom: 0.5rem;
}

.form-sm .form-control-label {
    margin-bottom: 0.1rem;
}

.form-control {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
}

.table-header {
    border: none;
    flex: 0 0 auto;
    justify-content: space-between;
    display: flex;
}

.table-footer {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
}

.w-card {
    width: 500px;
}

.top-25 {
    margin-top: 15rem;
}

.cursor-default {
    cursor: default !important;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default !important;
}

.sidebar {
    background-color: white;
    height: 100%;
    display: flex;
    flex-flow: column;
}

.sidebar .sidenav-list {
    display: flex;
    flex: 1 0 auto;
    flex-flow: column;
}

.sidebar .sidenav-list .side-nav {
    display: flex;
    flex-flow: column;
    list-style: none;
    padding-left: 0;
}

.sidebar .sidenav-list .f-end {
    margin-top: auto;
}

.sidenav-list .side-nav .side-item {
    display: flex;
    justify-content: center;
    min-height: 50px;
}

.sidenav-list .side-nav .side-item > a {
    align-self: center;
}

.card-stats + .card-stats {
    margin-left: 10px;
}

.nav-link {
    padding: 0;
}

.nav-link.active {
    color: #e0007a;
}

.nav-link.active:hover {
    color: #e0007a;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out;
}

.btn-add {
    margin-left: 1em;
}

.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
}

.DayPicker-Day {
    border-radius: 0 !important;
}

.DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}

.DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}

.DayPickerInput-Overlay {
    margin-right: -500px;
    z-index: 2000;
}

.deco-none {
    color: inherit;
    text-decoration: inherit;
}

.deco-none:link {
    color: inherit;
    text-decoration: inherit;
}

.deco-none:hover {
    color: inherit;
    text-decoration: inherit;
}

.basic-font {
    font-weight: normal !important;
    font-size: 0.8125rem !important;
}

.dropdown-menu {
    z-index: 2000;
}

.responsive-chart > div {
    flex: 1 1 auto !important;
}

.sticky {
    position: sticky !important;
    position: -webkit-sticky !important;
    z-index: 2;
}

.with-shadow {
    position: relative;
    box-shadow: 0 0;
}

.with-shadow:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
}

.truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.text-small-uppercase {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 0.65rem;
}

@keyframes scale-in-hor-left {
    0% {
        transform: scaleX(0);
        transform-origin: 0 0;
        opacity: 1;
    }
    100% {
        transform: scaleX(1);
        transform-origin: 0 0;
        opacity: 1;
    }
}

.scale-in-hor-left {
    animation-name: scale-in-hor-left;
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-fill-mode: both;
}

@keyframes scale-out-hor-left {
    0% {
        transform: scaleX(1);
        transform-origin: 0 0;
        opacity: 1;
    }
    100% {
        transform: scaleX(0);
        transform-origin: 0 0;
        opacity: 1;
    }
}

.scale-out-hor-left {
    animation-name: scale-out-hor-left;
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    animation-fill-mode: both;
}

@keyframes shadow-drop-2-br {
    0% {
        transform: translateZ(0) translateX(0) translateY(0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
        transform: translateZ(50px) translateX(-12px) translateY(-12px);
        box-shadow: 12px 12px 20px -12px rgba(0, 0, 0, 0.35);
    }
}

.shadow-drop-2-br {
    animation-name: shadow-drop-2-br;
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-fill-mode: both;
}

@keyframes scale-in-ver-top {
    0% {
        transform: scaleY(0);
        transform-origin: 100% 0;
        opacity: 1;
    }
    100% {
        transform: scaleY(1);
        transform-origin: 100% 0;
        opacity: 1;
    }
}

.scale-in-ver-top {
    animation-name: scale-in-ver-top;
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-fill-mode: both;
}

@keyframes scale-out-ver-top {
    0% {
        transform: scaleY(1);
        transform-origin: 100% 0;
        opacity: 1;
    }
    100% {
        transform: scaleY(0);
        transform-origin: 100% 0;
        opacity: 1;
    }
}

.scale-out-ver-top {
    animation-name: scale-out-ver-top;
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-fill-mode: both;
}

.align-horizontal-vertical {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

/* disable arrows from Number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

/* Vertical line */
.vertical-line {
    border-left: 1px solid grey;
    opacity: 0.5;
    height: 90%;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 0;
}

.display-none {
    display: none;
}

.calls-by-agent {
    margin-bottom: 10px;
}

.sticky-top th:after,
.sticky-top th:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
}

.sticky-top th:before {
    top: -1px;
    border-top: 2px solid #e9ecef;
}

.sticky-top th:after {
    bottom: -1px;
    border-bottom: 2px solid #e9ecef;
}

@supports (position: sticky) {
    .sticky-left {
        position: sticky;
        left: 0;
        z-index: 1020;
    }
}

/* CSS RenderAllCallsStatistics */
.render-all-calls-statistics ul {
    background-color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.render-all-calls-statistics-spinner {
    position: absolute;
    width: 100%;
}

.bg-spinner-calls-statistics {
    background-color: rgba(255, 255, 255, 0.4) !important;
    height: 50vh;
}

.visually-hidden {
    display: none !important;
}

/* Disable Link */
.disabled-link {
    pointer-events: none;
    cursor: not-allowed;
    color: grey;
}

/*Hide eye in password input*/
input::-ms-clear,
input::-ms-reveal {
    display: none;
}
