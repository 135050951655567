.croo-table-list-container {
    overflow-x: visible !important;
}

.croo-table-header {
    padding: 1em .5em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 0.65rem;
}

.croo-table-header-row {
    border-bottom: 1px solid var(--lighter);
    border-top: 1px solid var(--lighter);
    font-weight: 600;
    font-size: 0.65rem;
    background: var(--secondary);
}

.croo-table-footer {
    padding: 1em .5em;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid var(--lighter);
    font-weight: 600;
    font-size: 0.65rem;
    background: var(--secondary);
}

.croo-table-row {
    overflow: visible;
    border-bottom: 1px solid var(--lighter);
}

.croo-table-row > .croo-table-cell:last-child {
    padding-right: 1em;
}

.croo-table-cell {
    padding: .2em .5em;
    letter-spacing: 1px;
    font-size: 0.8125rem;
}

.croo-table .croo-table-row:hover {
    background: rgba(246, 249, 252, 0.3);
}

/**** CSS table of popover call history ****/
.croo-table-cell-history {
    padding: 0px !important;
}

.croo-table-cell-history table {
    margin-bottom: 0px !important;
}

.croo-table-cell-history thead {
    background: var(--secondary);
}
