.sliding-panel-container {
    position: fixed;
    top: 0;
    left: 0;
}

.sliding-panel-container.active {
    position: fixed;
    z-index: 1030;
    background-color: rgba(0, 0, 0, 0.3);
    will-change: transform;
}

.panel-content {
    height: 100%;
    width: 100%;
}

.panel-container-left-enter {
    opacity: 0;
    transform: translateX(-100%);
}
.panel-container-left-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms, opacity 500ms;
}
.panel-container-left-exit {
    opacity: 1;
    transform: translateX(0);
}
.panel-container-left-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 500ms, opacity 500ms;
}

.panel-container-right-enter {
    opacity: 0;
    transform: translateX(100%);
}
.panel-container-right-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms, opacity 500ms;
}
.panel-container-right-exit {
    opacity: 1;
    transform: translateX(0);
}
.panel-container-right-exit-active {
    opacity: 0;
    transform: translateX(100%);
    transition: transform 500ms, opacity 500ms;
}

.panel-container-top-enter {
    opacity: 0;
    transform: translateY(-100%);
}
.panel-container-top-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms, opacity 500ms;
}
.panel-container-top-exit {
    opacity: 1;
    transform: translateX(0);
}
.panel-container-top-exit-active {
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 500ms, opacity 500ms;
}

.panel-container-bottom-enter {
    opacity: 0;
    transform: translateY(100%);
}
.panel-container-bottom-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: transform 500ms, opacity 500ms;
}
.panel-container-bottom-exit {
    opacity: 1;
    transform: translateX(0);
}
.panel-container-bottom-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: transform 500ms, opacity 500ms;
}

.panel {
    box-shadow:  0 2rem 0 rgba(136, 152, 170, 0.95);
}