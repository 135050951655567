.navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand > img {
    max-height: 2.4rem;
}

.sidenav-header {
    height: 64px;
}

.navbar-vertical {
    border-style: none;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}

p, a {
    font-weight: 300 !important;
}

a.active, li.active > a {
    font-weight: 400 !important;
}

li.active > a :not(.page-active) {
    color: #E0007A !important;
}

button {
    font-family: Montserrat, sans-serif !important;
}

input[type="checkbox"] {
    visibility: hidden;
}

label {
    cursor: pointer;
}
input[type="checkbox"] {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.checkbox {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    transition: all 150ms;
}

.react-select__control {
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    display: block;
    width: 100%;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
    transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.btn-primary:focus, .btn-primary.focus {
    background-color: #E0007A;;
    border-color: #E0007A;;
}

.custom-toggle-slider {
    border-radius: 17px !important;
}

.custom-toggle input:checked + .custom-toggle-slider:before {
    transform: translateX(10px);
}

.custom-toggle {
    width: 26px;
    height: 1rem;
}

.custom-toggle-slider:before {
    height: 12px;
    width: 12px;
    left: 1px;
    bottom: 1px;
}

.custom-toggle-slider:after {
    color: #ced4da;
    font-weight: 400;
    font-size: .37rem;
    line-height: 16px;
    margin: 0 .21667rem;
    min-width: 0.8rem;
}

.popover {
    max-width: 500px !important;
}